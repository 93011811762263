var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-bodyz"
  }, [_c('h5', {
    staticClass: "text-center mb-5 mt-4"
  }, [_vm._v(" " + _vm._s(_vm.$t("amendment.guardian-info")) + " "), _c('img', {
    directives: [{
      name: "tippy",
      rawName: "v-tippy",
      value: {
        content: _vm.$t('recipient-grant-underage-oku')
      },
      expression: "{ content: $t('recipient-grant-underage-oku')}"
    }],
    staticClass: "mb-1",
    attrs: {
      "height": "20",
      "src": "/images/icon/info-button.png"
    }
  })]), _c('div', {
    staticClass: "text-right mr-2"
  }, [_vm.caretakerBtnStatus ? _c('router-link', {
    staticClass: "btn btn-primary btn-sm",
    attrs: {
      "to": {
        name: 'trust-onecent.caretaker.create',
        query: {
          trust_id: this.trustId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("add")))]) : _vm._e()], 1), _c('div', {
    staticClass: "row mb-2"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "table-responsive p-2 mt-3 mb-4"
  }, [_c('table', {
    staticClass: "table table-sm"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.$t("name")))]), _c('th', [_vm._v(_vm._s(_vm.$t("mykad")))]), _c('th', [_vm._v(_vm._s(_vm.$t("relationship")))]), _c('th', [_vm._v(_vm._s(_vm.$t("mobile-no")))]), _c('th', [_vm._v(_vm._s(_vm.$t("action")))])])]), _c('tbody', [_vm._l(_vm.caretakers, function (caretaker, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(caretaker.name))]), _c('td', [_vm._v(_vm._s(caretaker.ic_number))]), _c('td', [_vm._v(_vm._s(caretaker.relationship))]), _c('td', [_vm._v(_vm._s(caretaker.mobile_number))]), _c('td', [_vm.caretakerBtnStatus ? _c('div', {
      staticClass: "btn-group-vertical",
      attrs: {
        "role": "group",
        "aria-label": "Vertical button group"
      }
    }, [_c('router-link', {
      staticClass: "btn btn-secondary btn-sm",
      attrs: {
        "to": {
          name: 'trust-onecent.caretaker.update',
          query: {
            trust_id: _vm.trustId,
            caretaker_id: caretaker.id,
            amendment_id: _vm.amendmentId
          }
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("update")))]), _c('button', {
      staticClass: "btn btn-danger btn-sm",
      on: {
        "click": function ($event) {
          return _vm.removeCaretaker(caretaker.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("remove")) + " ")])], 1) : _vm._e()])]);
  }), _vm.caretakers && _vm.allow_caretaker && _vm.caretakers.length == 0 ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "5"
    }
  }, [_c('h6', {
    staticClass: "mt-5 mb-5"
  }, [_vm._v(_vm._s(_vm.$t("no-data")))])])]) : _vm._e(), !_vm.allow_caretaker ? _c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "5"
    }
  }, [_c('h6', {
    staticClass: "mt-5 mb-5"
  }, [_vm._v(" " + _vm._s(_vm.$t("trust.no-guardian")) + " ")])])]) : _vm._e()], 2)])])])])])]), _c('div', {
    staticClass: "row mt-4"
  }, [_c('div', {
    staticClass: "col-lg-12 text-right"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }), _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'trust-onecent.part-h',
        query: {
          trust_id: _vm.trustId,
          amendment_id: _vm.amendmentId
        }
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("next")) + " "), _vm.submit ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])], 1)])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }